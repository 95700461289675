import React from "react";

export default function SliderThree() {
  return (
    <div className="jewellery_section">
      <div
        id="jewellery_main_slider"
        className="carousel slide"
        data-ride="carousel">
        <div className="carousel-inner">
          <div className="carousel-item active">
            <div className="container">
              <h1 className="fashion_taital">Jewellery Accessories</h1>
              <div className="fashion_section_2">
                <div className="row">
                  <div className="col-lg-4 col-sm-4">
                    <div className="box_main">
                      <h4 className="shirt_text">Jumkas</h4>
                      <p className="price_text">
                        Start Price
                        {/* <span style="color: #262626;">$ 100</span> */}
                      </p>
                      <div className="jewellery_img">
                        <img src="images/jhumka-img.png" />
                      </div>
                      <div className="btn_main">
                        <div className="buy_bt">
                          <a href="#">Buy Now</a>
                        </div>
                        <div className="seemore_bt">
                          <a href="#">See More</a>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-4 col-sm-4">
                    <div className="box_main">
                      <h4 className="shirt_text">Necklaces</h4>
                      <p className="price_text">
                        Start Price
                        {/* <span style="color: #262626;">$ 100</span> */}
                      </p>
                      <div className="jewellery_img">
                        <img src="images/neklesh-img.png" />
                      </div>
                      <div className="btn_main">
                        <div className="buy_bt">
                          <a href="#">Buy Now</a>
                        </div>
                        <div className="seemore_bt">
                          <a href="#">See More</a>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-4 col-sm-4">
                    <div className="box_main">
                      <h4 className="shirt_text">Kangans</h4>
                      <p className="price_text">
                        Start Price
                        {/* <span style="color: #262626;">$ 100</span> */}
                      </p>
                      <div className="jewellery_img">
                        <img src="images/kangan-img.png" />
                      </div>
                      <div className="btn_main">
                        <div className="buy_bt">
                          <a href="#">Buy Now</a>
                        </div>
                        <div className="seemore_bt">
                          <a href="#">See More</a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="carousel-item">
            <div className="container">
              <h1 className="fashion_taital">Jewellery Accessories</h1>
              <div className="fashion_section_2">
                <div className="row">
                  <div className="col-lg-4 col-sm-4">
                    <div className="box_main">
                      <h4 className="shirt_text">Jumkas</h4>
                      <p className="price_text">
                        Start Price
                        {/* <span style="color: #262626;">$ 100</span> */}
                      </p>
                      <div className="jewellery_img">
                        <img src="images/jhumka-img.png" />
                      </div>
                      <div className="btn_main">
                        <div className="buy_bt">
                          <a href="#">Buy Now</a>
                        </div>
                        <div className="seemore_bt">
                          <a href="#">See More</a>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-4 col-sm-4">
                    <div className="box_main">
                      <h4 className="shirt_text">Necklaces</h4>
                      <p className="price_text">
                        Start Price
                        {/* <span style="color: #262626;">$ 100</span> */}
                      </p>
                      <div className="jewellery_img">
                        <img src="images/neklesh-img.png" />
                      </div>
                      <div className="btn_main">
                        <div className="buy_bt">
                          <a href="#">Buy Now</a>
                        </div>
                        <div className="seemore_bt">
                          <a href="#">See More</a>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-4 col-sm-4">
                    <div className="box_main">
                      <h4 className="shirt_text">Kangans</h4>
                      <p className="price_text">
                        Start Price
                        {/* <span style="color: #262626;">$ 100</span> */}
                      </p>
                      <div className="jewellery_img">
                        <img src="images/kangan-img.png" />
                      </div>
                      <div className="btn_main">
                        <div className="buy_bt">
                          <a href="#">Buy Now</a>
                        </div>
                        <div className="seemore_bt">
                          <a href="#">See More</a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="carousel-item">
            <div className="container">
              <h1 className="fashion_taital">Jewellery Accessories</h1>
              <div className="fashion_section_2">
                <div className="row">
                  <div className="col-lg-4 col-sm-4">
                    <div className="box_main">
                      <h4 className="shirt_text">Jumkas</h4>
                      <p className="price_text">
                        Start Price
                        {/* <span style="color: #262626;">$ 100</span> */}
                      </p>
                      <div className="jewellery_img">
                        <img src="images/jhumka-img.png" />
                      </div>
                      <div className="btn_main">
                        <div className="buy_bt">
                          <a href="#">Buy Now</a>
                        </div>
                        <div className="seemore_bt">
                          <a href="#">See More</a>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-4 col-sm-4">
                    <div className="box_main">
                      <h4 className="shirt_text">Necklaces</h4>
                      <p className="price_text">
                        Start Price
                        {/* <span style="color: #262626;">$ 100</span> */}
                      </p>
                      <div className="jewellery_img">
                        <img src="images/neklesh-img.png" />
                      </div>
                      <div className="btn_main">
                        <div className="buy_bt">
                          <a href="#">Buy Now</a>
                        </div>
                        <div className="seemore_bt">
                          <a href="#">See More</a>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-4 col-sm-4">
                    <div className="box_main">
                      <h4 className="shirt_text">Kangans</h4>
                      <p className="price_text">
                        Start Price
                        {/* <span style="color: #262626;">$ 100</span> */}
                      </p>
                      <div className="jewellery_img">
                        <img src="images/kangan-img.png" />
                      </div>
                      <div className="btn_main">
                        <div className="buy_bt">
                          <a href="#">Buy Now</a>
                        </div>
                        <div className="seemore_bt">
                          <a href="#">See More</a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <a
          className="carousel-control-prev"
          href="#jewellery_main_slider"
          role="button"
          data-slide="prev">
          <i className="fa fa-angle-left"></i>
        </a>
        <a
          className="carousel-control-next"
          href="#jewellery_main_slider"
          role="button"
          data-slide="next">
          <i className="fa fa-angle-right"></i>
        </a>
        <div className="loader_main">
          <div className="loader"></div>
        </div>
      </div>
    </div>
  );
}
