import React from "react";
import Navigation from "components/home/Navigation";
import MainSlider from "components/home/MainSlider";
import SliderOne from "components/home/SliderOne";
import SliderTwo from "components/home/SliderTwo";
import SliderThree from "components/home/SliderThree";
import Footer from "components/home/Footer";

export default function Main() {
  return (
    <>
      <div className="banner_bg_main">
        <Navigation />
        <MainSlider />
        <SliderOne />
        <SliderTwo />
        <SliderThree />
        <Footer />
      </div>
    </>
  );
}
